<template>
   <!--begin::Card-->
   <div>
      <div class="card card-custom "  >
         <!--begin::Header-->
         <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
               <h3 class="card-label font-weight-bolder text-dark">
                  {{$t('Integrations')}}
               </h3>
               <span class="text-muted font-weight-bold font-size-sm mt-1"
                  >{{$t('Integrate your client informations')}}</span
                  >
            </div>
         </div>
         <!--end::Header-->
         <!--begin::Form-->
         <form class="form"  dir="ltr">
            <div class="card-body">
               <!--begin::Form Group-->
               <div
                  class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">API Key</label>
                  <div class="col-lg-9 col-xl-6">
                     <b-form-input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        v-model="client.secret_key"
                        disabled
                        />
                  </div>
                  <i class="fas fa-copy col-xl-1 mt-4 px-0" @click="copy"></i>
               </div>
               <div class="col-lg-12 rounded p-3">
                  <label class="col-xl-3 col-lg-3 col-form-label">Create order api</label>
                  <code class="">
                  <br>
                  curl --location --request POST 'https://apidev.vaiila.com/api/v1/apiOrderRequest' \ <br>
                  --form 'amount=" 3"' \ <br>
                  --form 'customer_name="ahmed"' \ <br>
                  --form 'delivery_lat="31.98465"' \ <br>
                  --form 'delivery_long="35.88468"' \<br>
                  --form 'pickup_lat="31.993432"' \<br>
                  --form 'pickup_long="35.86180"' \<br>
                  --form 'phone="0000000000"' \<br>
                  --form 'zone_name="test zone"' \<br>
                  --form 'key="api key"'
                  <br>
                  </code>
                  <label class="col-xl-3 col-lg-3 col-form-label">Sample Response</label>
                  <code class="">
                  <br>
                  {<br>
                  &nbsp;&nbsp;"order_id": 1189,<br>
                  &nbsp;&nbsp;"mesg": "created successfuly",<br>
                  &nbsp;&nbsp;"fees": "2.00"<br>
                  }<br>
                  <br>
               </div>
               <div class="col-lg-12 rounded p-3">
                  <label class="col-xl-3 col-lg-3 col-form-label">Check order status api</label>
                  <code class="">
                  <br>
                  curl --location --request POST 'https://apidev.vaiila.com/api/v1/apiOrderStatus' \ <br>
                  --form 'order_id="765"' \ <br>
                  --form 'key="api key"' <br>
                  <br>
                  </code>
                  <label class="col-xl-3 col-lg-3 col-form-label">Sample Response</label>
                  <code class="">
                  <br>
                  { <br>
                  &nbsp;&nbsp;"Order ID": 765, <br>
                  &nbsp;&nbsp;"Status": "Created" <br>
                  } <br>
                  <br>
               </div>
               <div class="col-lg-12 rounded p-3">
                  <label class="col-xl-3 col-lg-3 col-form-label">Check location fees api</label>
                  <code class="">
                  <br>
                  curl --location --request POST 'https://apidev.vaiila.com/api/v1/apiLocationFees' \<br>
                  --form 'key="api key"' \<br>
                  --form 'pickup_lng="35.86180"' \<br>
                  --form 'pickup_lat="31.993432"' \<br>
                  --form 'delivery_lat="31.98465"' \<br>
                  --form 'delivery_lng="35.88468"' \<br>
                  <br>
                  </code>
                  <label class="col-xl-3 col-lg-3 col-form-label">Sample Response</label>
                  <code class="">
                  <br>
                  {<br>
                  &nbsp;&nbsp;"fees": "2.00"<br>
                  }            <br>
                  <br>
               </div>
               <div class="col-lg-12 rounded p-3">
                  <label class="col-xl-3 col-lg-3 col-form-label">Bulk Check location fees api</label>
                  <code class="">
                  <br>
                  curl --location --request POST 'https://apidev.vaiila.com/api/v1/apibulklocationFees' \<br>
                  --header 'Content-Type: application/json' \<br>
                  --data-raw '{<br>
                  "key":" api key",<br>
                  "pickup_lng":"35.86180",<br>
                  "pickup_lat":"31.993432",<br>
                  "delivery_locations":[<br>
                  {<br>
                  "id":"1",<br>
                  "lng":"35.88468",<br>
                  "lat":"31.98465"<br>
                  },<br>
                  {<br>
                  "id":"2",<br>
                  "lng":"35.88468",<br>
                  "lat":"31.98465"<br>
                  }<br>
                  ]<br>
                  }'       <br>     
                  <br>
                  </code>
                  <label class="col-xl-3 col-lg-3 col-form-label">Sample Response</label>
                  <code class="">
                  <br>
                  {<br>
                  &nbsp;&nbsp;"fees": {<br>
                  &nbsp;&nbsp;"1": "2.00",<br>
                  &nbsp;&nbsp;"2": "2.00"<br>
                  &nbsp;}<br>
                  }<br>
                  <br>
                  </code>
               </div>
            </div>
         </form>
         <!--end::Form-->
      </div>
   </div>
   <!--end::Card-->
</template>
<script>
   import Swal from "sweetalert2";
   
   
   
   export default {
     name: "AccountInformation",
   
     props: {
       client: {
         type: Object,
         required: true,
       },
     },
   
     data() {
       return {
         
       };
     },
     async mounted() {
      // console.log(this.client)
       //this.$store.dispatch('clients/getAPI')
      
       
     },
    
     methods: {
       copy(){
           navigator.clipboard.writeText(this.param1);
           this.$bvToast.toast('Copied to clipbaord', {
             title: 'Copy to clipboard',
             
             solid: true
           })
         
       },
       save() {
         // set spinner to submit button
         const submitButton = this.$refs["kt_save_changes"];
         submitButton.classList.add("spinner", "spinner-light", "spinner-right");
   
         // dummy delay
         setTimeout(() => {
           // send update request
           
           //this.$store.dispatch("clients/update", this.client);
   
           submitButton.classList.remove(
             "spinner",
             "spinner-light",
             "spinner-right"
           );
   
           Swal.fire({
             title: "",
             text: "Client has been updated successfully!",
             icon: "success",
             confirmButtonClass: "btn btn-secondary",
           });
         }, 2000);
       },
       
     },
   };
</script>