<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Account Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change your account settings')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->

        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.client.name.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Full Name')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="client.name"
              :class="{ 'form-input--error': $v.client.name.$error }"
              :state="$v.client.name.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.client.phone.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('phone')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="client.phone"
              :class="{ 'form-input--error': $v.client.phone.$error }"
              :state="$v.client.phone.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.client.long.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label"
            >{{$t('long')}}</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="number"
              ref="LocationLongitude"
              v-model="client.long"
              :class="{ 'form-input--error': $v.client.long.$error }"
              :state="$v.client.long.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.client.lat.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label"
            >{{$t('lat')}}</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="client.lat"
              :class="{ 'form-input--error': $v.client.lat.$error }"
              :state="$v.client.lat.$error === true ? false : null"
            />
          </div>
        </div>

        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.client.area.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Area')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="client.area"
              :class="{ 'form-input--error': $v.client.area.$error }"
              :state="$v.client.area.$error === true ? false : null"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('City')}}</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              v-model="client.city"
            >
              <template v-for="zone in client.zones">
                <option
                  :key="zone.id"
                  :value="zone.name_ar"
                  :selected="client.city == zone.name_ar ? true : false"
                >
                  {{ zone.name_ar }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AccountInformation",

  props: {
    client: {
      type: Object,
      required: true,
    },
    submitStatus: null,
  },
  data() {
    return {};
  },
  validations: {
    client: {
      name: {
        required,
      },
      phone: {
        required,
      },
      long: {
        required,
      },
      lat: {
        required,
      },
      tookan_foreign_id: {
        required,
      },
      area: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch("clients/update", this.client);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          Swal.fire({
            title: "",
            text: "Client has been update successfully!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    cancel() {},
  },
};
</script>
<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>