<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Pricing Map')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Check pricing map for this client')}}</span
        >
      </div>
      <!--<div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>-->
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Form Group-->

        <GmapMap
                  style="width: 100%; height: 50vh"
                  :center="{
                    lat: currentLocation.latitude,
                    lng: currentLocation.longitude,
                  }"
                  :zoom="10"
                >
                  <GmapMarker
                    label="★"
                    :position="{
                      lat: currentLocation.latitude,
                      lng: currentLocation.longitude,
                    }"
                  />
                  <!--<GmapCircle
                    ref="circleRef"
                    :center="{
                      lat: currentLocation.latitude,
                      lng: currentLocation.longitude,
                    }"
                    :radius="distance.base * 1000"
                    :visible="true"
                    :options="{
                      fillColor: 'blue',
                      fillOpacity: 0.0,
                      strokeWeight: 1,
                      clickable: true,
                    }"
                  />-->

                  <div v-for="t in zone_price" :key="t.id">
                    <GmapCircle
                      ref="circleRef"
                      :center="{
                        lat: currentLocation.latitude,
                        lng: currentLocation.longitude,
                      }"
                      :radius="t.distance * 1000"
                      :visible="true"
                      :options="{
                        fillColor: 'red',
                        fillOpacity: 0.0,
                        strokeWeight: 1,
                        clickable: true,
                        strokeOpacity: 0.4,
                      }"
                    />
                  </div>
                </GmapMap>

                <div class="col-xl-12 mt-4" v-for="zone in zone_price" :key="zone.id">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend>
                    <b-input-group-text style="width: 75px">
                      {{zone.name}}
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    :id="zone.id.toString()"
                    v-model="zone.price"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> {{$t('JOD')}} </b-input-group-text>
                  </b-input-group-append>
                  <b-input-group-append>
                    <b-input-group-text style="width: 70px">
                      {{ zone.distance }} {{$t('KM')}}
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>
       
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>



export default {
  name: "PricingView",

  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
        currentLocation: { latitude: 0, longitude: 0 },
        zone_price:''
    };
  },
  mounted(){
      this.zone_price = JSON.parse(this.client.client_tarrifs.zones)
    //this.user = await this.$store.dispatch(VERIFY_AUTH);
    //console.log(this.zone_price)
    //var cl = this.client
    this.currentLocation.longitude = parseFloat(this.client.long);
    this.currentLocation.latitude = parseFloat(this.client.lat);
  },
  
  methods: {
    
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>
