<template>
  <!--begin::Card-->
  <div>
   

    <div class="card card-custom "  >
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            {{$t('Zones Information')}}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1"
            >{{$t('Change your Pricing Information settings')}}</span
          >
        </div>
        <div class="card-toolbar"  dir="ltr">
          <button
            type="reset"
            class="btn btn-success mr-2"
            @click="save()"
            ref="kt_save_changes"
          >
            {{$t('Save Changes')}}
          </button>
          <button type="reset" class="btn btn-secondary" @click="cancel()">
            {{$t('cancel')}}
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <form class="form"  dir="ltr">
        <div class="card-body">
          <div class="mb-3 text-right d-flex justify-content-between" >
           <span class="mt-2">{{toggleValue}}</span> 
              <span class="switch switch-icon">
                
                <label>
                  <input
                    type="checkbox"
                    name="select"
                    v-model="toggledChange"
                    @input="setToggleChange"
                    
                  />
                  <span></span>
                </label>
              </span>
            </div>
          <!--begin::Form Group-->

        
           <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label" v-if="toggledChange === false">{{$t('SelectedTarrif')}}</label>
          <div class="col-lg-9 col-xl-6" v-if="toggledChange === false">
            <select
              class="form-control form-control-lg form-control-solid"
              v-model="client.selected_tarrif"
            >
              <template v-for="tarrif in tarrifs">
                <option
                  :key="tarrif.id"
                  :value="tarrif.id"
                  :selected="tarrif.id == client.selected_tarrif ? true : false"
                >
                  {{ tarrif.tarrif_name }}
                </option>
              </template>
            </select>
          </div>


          
          <div class="col-lg-12 col-xl-12" v-if="client.selected_tarrif !== null && toggledChange === true">
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0  mt-5" v-for="zone in zones" :key="zone.id"> 
                  <b-input-group-prepend>
                   <b-input-group-text style="width: 120px"> {{zone.name_ar}}</b-input-group-text>
                  </b-input-group-prepend>

                  <b-form-input
                  v-model="zone.price"
                  
                     
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> displayed price </b-input-group-text>
                  </b-input-group-append>
                  <b-form-input
                    v-model="zone.displayed_price"
                    
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text> {{$t('JOD')}} </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
          </div>
        </div>

    
        
     
        </div>
      </form>

      
      <!--end::Form-->
    </div>
    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="$t('Zone Deletion')"
      hide-footer
    >
      <p v-if="$t('clan')=='en'">
        Are you sure that you want to delete this Zone from the client zones?, if you agree you
        will loose all information about Zone
      </p>
      <p v-else>
        هل أنت متأكد برغبتك بحذف هذه المنطقة من مناطق هذا الوكيل؟ بموافقتك سوف تخسر كل شيء متعلق بها
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >{{$t('cancel')}}</b-button
        >
        <b-button variant="danger" @click="deleteClientZone">{{$t('Delete')}}</b-button>
      </div>
    </b-modal>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

import { required } from "vuelidate/lib/validators";


export default {
  name: "AccountInformation",

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tarrifs:[],
      zones:[],
      title: "custom",
      price: "",
      submitStatus: "",
      toggleValue: "Distance Mode",

      distance_zones:'',
      clientZoneId:'',

      toggledChange:false
    };
  },
  validations: {
    price: {
      required,
    },
  },
  async mounted() {
    //console.log(this.client)
    var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.zones = this.client.zones
    //console.log(zones)
    this.tarrifs = tarrifs.data

    if (this.client.pricing_mode === "distance_mode") {
      
      this.toggledChange = false
      this.toggleValue = "Zone Mode"
      
    } else {
     
      this.toggledChange = true
      this.toggleValue = "Distance Mode"
    
    }
    
    

    
    this.distance_zones =JSON.parse(this.client.distance_pricing)

    
  },
 
  methods: {
    setToggleChange(){
      //this.toggledChange = ! this.toggledChange
      //console.log(this.toggledChange)
      if(this.toggledChange === true){
        this.toggledChange = false
        this.client.pricing_mode = 'distance_mode'
        this.toggleValue = "Distance Mode"
      }
      else if(this.toggledChange === false){
        this.toggledChange = true
        this.client.pricing_mode = 'geofence_mode'
        this.toggleValue = "Zone Mode"
      }
    },
    add(){
      
      if(this.distance_zones ===null){
        this.distance_zones = [{id:1 , name:'zone1' , distance:1 , price:2}]
      }else{

        this.distance_zones.push({id:this.distance_zones.length+1 , 
      name:'zone'+(parseInt(this.distance_zones.length)+1) , distance:1, price:2})
      }
    },
    remove(id){
      this.distance_zones = this.distance_zones.filter(s=>s.id !==id)
    },
    
    reset() {
      for (var x = 0; x < this.client.zones.length; x++) {
        var s = this.table.items.filter(
          (s) => s.name_en === this.client.zones[x].name_en
        );
        var price = s[0].price;
        this.client.zones[x].price = price;
      }
    },
    custom() {
      if (this.title === "custom") {
        this.title = "cancel";
      } else {
        this.title = "custom";
      }
    },
    apply() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        for (var x = 0; x < this.client.zones.length; x++) {
          this.client.zones[x].price = this.price;
        }

        this.title = "custom";
        this.price = "";

        Swal.fire({
          title: "",
          text: "You have Successfully changed the price!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        if(this.toggledChange === true){
          this.client.selected_tarrif = -1
        }
        this.$store.dispatch("clients/update", this.client);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Client has been updated successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    async deleteClientZone(){
      //console.log(this.clientZoneId)
      await this.$store.dispatch('clients/deleteClintZone' , this.clientZoneId)
        //console.log(res)
         
        this.hideModal()
        

      await this.$store.dispatch('clients/loadAll')
    for(var s=1; s<=localStorage.getItem('lastPage') ; s++){
      await this.$store.dispatch('clients/loadPage',s)
    }

Swal.fire({
          title: "",
          text: "Client zone has been removed successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
    
/*if(localStorage.getItem('page')!==1){

        
        /*this.$store.dispatch('clients/loadPage',localStorage.getItem('page')).then(res=>{
          console.log(res)
          var index = res.data.data.findIndex(x=>x.id == this.client.id)
          //console.log(res.data.data[index])
          this.client = res.data.data[index]
        })*/
  //console.log(this.client.id)
  /*var edit={
    id:this.client.id,
    page:localStorage.getItem('page')
  }*/
  //console.log(edit)
        /*this.$store.dispatch('clients/loadPageEdited',edit)
        }*/
      

      
     
    },
    showModal(id) {
      //console.log(id)
      this.$refs["my-modal"].show();
      this.clientZoneId = id
      
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    async saveDistance() {
      this.client.distance_pricing = ''
      var obj = JSON.stringify(this.distance_zones)
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes1"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      await this.$store.dispatch("clients/update", this.client);

      // dummy delay
      setTimeout(() => {
        // send update request
        this.client.distance_pricing = obj
        this.$store.dispatch("clients/update", this.client);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Client has been updated successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    cancel() {},
  },
};
</script>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
.group {
  margin-left: -35px;
}

@media only screen and (max-width: 1024px) {
  .x {
    text-align: center;
    margin: auto;
    display: flex;
    display: grid;
    width: 100%;
  }
}
.error {
  color: rgb(235, 128, 41);
}
.te:hover{
  color: rgb(247, 71, 71);
  cursor: pointer;
}  

</style>
